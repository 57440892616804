<template>
	<b-modal id="notification-incident" ref="notification-incident" size="lg" 
		:title="title" @cancel="handleClose" ok-only>
		<div v-if="Object.keys(incidentReport).length === 0" :style="{ height: '100px' }">
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		</div>
		<div v-else>
			<!-- Primary Information -->
			<b-row>
				<b-col md="12" class="md-left-text-sm-right">
					<b>PRIMARY INFORMATION</b>
				</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Summary</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					incidentReport.summary
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Type</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					incidentReport.incidentType.name
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Remarks</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">
					<span class="truncate-text">
						<truncate collapsed-text-class="collapsed" clamp="Show More" less="Show Less"
							type="html" :text="breakRemarks(incidentReport.remarks, 25)" :length="50" />
					</span>
				</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Location</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					getCoordinates(incidentReport)
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Source</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">
					{{ incidentReport.source.company }} - {{ incidentReport.source.storageLocation }}
				</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Destination</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">
					{{ incidentReport.destination.company }} - {{ incidentReport.destination.storageLocation }}
				</b-col>
			</b-row>

			<!-- Transportation -->
			<b-row class="mt-4">
				<b-col md="12" class="md-left-text-sm-right">
					<b>TRANSPORTATION</b>
				</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Plate No./Conduction No.</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					incidentReport.transportation.plateNo
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Company</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					incidentReport.transportation.company
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Driver</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					incidentReport.driver.name
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Assistants</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					!incidentReport.transportation.assistants
					? '-'
					: incidentReport.transportation.assistants
				}}</b-col>
			</b-row>
		</div>
	</b-modal>
</template>

<script>
// Utils
import { LocationUtil } from '@/utils/locationUtil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from "vue-truncate-collapsed";

export default {
	name: 'notification-incident',
	components: {
		Loading,
		truncate
	},
	data() {
		return {
			incidentReport: {},
			isLoading: true,
		};
	},
	mounted() {
		EventBus.$on('onShowIncidentReportDetails', incidentReport => {
			this.incidentReport = incidentReport;
			this.$bvModal.show('notification-incident');
		});
	},
	computed: {
		title() {
			return 'Incident Details ' + this.incidentReport.incidentId;
		},
	},
	methods: {
		handleClose() {
			this.$bvModal.hide('notification-incident');
		},
		getCoordinates(geoaddress) {
			return LocationUtil.getCoordinates(geoaddress);
		},
		breakRemarks(remarks, length) {
			return remarks.length > length
				? remarks.replace(new RegExp(`([^\\s]{${length}})`, "g"), "$1<br>")
				: remarks;
		},
	}
};
</script>