<template>
	<div>
		<HeaderDropdown no-caret left>
			<template slot="header">
				<b-nav-item :class="notifAnimator">
					<span class="bell">
						<i class="icon-bell"></i>
					</span>
					<b-badge v-if="unreadCount" pill variant="danger">{{ unreadCount }}</b-badge>
				</b-nav-item>
			</template>
			<template slot="dropdown">
				<div :style="{ display: 'block' }">
					<b-dropdown-header tag="vue-custom-scrollbar" class="text-center bg-white">
						<strong v-if="unreadCount === 0">Notifications</strong>
						<strong v-else >You have {{ unreadCount }} unread message(s)</strong>
					</b-dropdown-header>
					<vue-custom-scrollbar class="notif-box">
						<template v-if="notifications.length">
							<NotificationItem v-for="item in notifications" :key="item.id" :item="item" />
						</template>
						<b-alert v-else show>
							<i class="fa fa-check-circle"></i>&nbsp; You have no messages today.
						</b-alert>
					</vue-custom-scrollbar>
				</div>
			</template>
		</HeaderDropdown>

		<ForceLogoutConfirmation />
		<NotificationDispatchDetails />
		<NotificationIncidentDetails />
	</div>
</template>

<script>
// Component
import NotificationItem from './NotificationItem';
import ForceLogoutConfirmation from '../../account/ForceLogoutConfirmation';
import NotificationDispatchDetails from './NotificationDispatchDetails';
import NotificationIncidentDetails from './NotificationIncidentDetails';

// API
import notificationDAO from '@/database/notifications';
import userSessionsDAO from '@/database/userSessions';

// Others
import { BIconBellFill } from 'bootstrap-vue';
import { HeaderDropdown } from '@coreui/vue';
import { firebase } from '@/config/firebase';
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
	name: 'Notifications',
	components: {
		HeaderDropdown,
		NotificationItem,
		BIconBellFill,
		ForceLogoutConfirmation,
		NotificationDispatchDetails,
		NotificationIncidentDetails,
		vueCustomScrollbar
	},
	data() {
		return {
			notifications: [],
			loggedUser: this.$store.getters.loggedUser,
			cachedIdToken: '',

			// Listeners
			notificationListener: '',
			userSessionListener: '',
		};
	},
	computed: {
		unreadCount() {
			return this.notifications.filter((item) => item.isViewed === false).length;
		},
		notifAnimator() {
			if (this.unreadCount > 0) {
				return 'animated infinite tada';
			}		
		},
	},
	mounted() {
		let email = this.loggedUser.emailAddress;
		this.notificationListener = notificationDAO.getNotificationListener({ email }, this.notificationListenerCallback);

		this.cachedIdToken = localStorage.getItem('token');
		if (this.cachedIdToken && this.cachedIdToken.length > 0) {
			this.userSessionListener = userSessionsDAO.getUserSessionListener({ email }, this.sessionListenerCallback);
		}	
	},
	methods: {
		notificationListenerCallback(type, notification) {
			if (type === "added") {
				if (notification.rules.deviceType == 'web' || notification.rules.deviceType == 'hybrid') {
					this.notifications.push({ id: notification.id, ...notification });
				}
			}
		},
		sessionListenerCallback(type, userSession) {
			const user = firebase.auth().currentUser;
			if (userSession.idToken !== this.cachedIdToken && user !== null && user.email === userSession.emailAddress) {
				this.$bvModal.show('force-logout-confirmation');
			}
		}
	},

	destroyed() {
		if (typeof this.notificationsListener === 'function') {
			this.notificationsListener();
		}
		if (typeof this.userSessionListener === 'function') {
			this.userSessionListener();
		}
	}
};
</script>

<style lang="scss" scoped>
@import '~animate.css';
.chat-box-holder {
	background-color: #f9f9fa;
	padding: 10px 10px;
	width: 400px;
	max-height: 80vh;
}
.notif-list-container {
	max-height: 500px;
}
.bell {
	color: #122c91;
}

.notif-box {
  position: relative;
  padding: 0.5em;
  height: 700px;
}

</style>
