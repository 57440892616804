import { db } from '@/config/firebase';

async function getIncidentReportByIncidentId(incidentId) {
    let query = db.collection('incidentReports')
        .where('incidentId', '==', incidentId)
    const querySnapshot = await query.get();

    let incidentReportObj = {};
    querySnapshot.forEach(item => {
        incidentReportObj = item.data();
        incidentReportObj.id = item.id;
    });

    return incidentReportObj;
}

export default {
    getIncidentReportByIncidentId
}