<template>
	<div>
		<AppHeaderDropdown no-caret>
			<template slot="header">
				<span class="user">
					<div class="row">
						<div class="col-md-8 py-2 d-md-down-none" display="lg">
							{{ username }}
						</div>
						<div class="col-md-4">
							<i class="fa fa-user-circle fa-2x mr-4 mt-2"></i>
						</div>
					</div>
					<div class="col-md-8 offset d-md-down-none" display="lg">
						{{ userCompany }}
					</div>
				</span>
			</template>
			<template slot="dropdown">
				<b-dropdown-header tag="div" class="text-center">
					<strong class="user">{{ userType.toUpperCase() }}</strong>
				</b-dropdown-header>
				<b-dropdown-item v-b-modal.user-profile>
					<i class="fa fa-user" /> Profile
				</b-dropdown-item>
				<b-dropdown-item @click="logout">
					<i class="fa fa-lock" /> Logout
				</b-dropdown-item>
			</template>
		</AppHeaderDropdown>

		<!-- Modals -->
		<UserProfile />
	</div>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue';
import UserProfile from '@/views/account/UserProfile';
import _ from 'lodash';

export default {
	name: 'DefaultHeaderDropdownAccnt',
	props: {
		username: {
			type: String,
			required: true,
		},
		userCompany: {
			type: String,
			required: true,
		},
		userType: {
			type: String,
			required: true,
		},
	},
	components: {
		AppHeaderDropdown,
		UserProfile,
	},
	data: () => {
		return { itemsCount: 42 };
	},
	async mounted() {
		this.$store.dispatch('initAuth');

		if (!this.$store.getters.isAuthenticated) {
			await this.logout();
		}
	},
	methods: {
		async logout() {
			try {
				await this.$store.dispatch('logout');
				let previousState = this.$store.getters.previousState;
				if (!_.isEmpty(previousState)) {
					this.$store.replaceState(previousState);
				}

				this.$router.push('/login');
			} catch (error) {
				this.$toaster.error(
					'Oops! Something went wrong logging out. Please try again.'
				);
			}
		},
	},
};
</script>
<style>
.user {
	color: #122c91;
}

.offset {
	margin-top: -12px;
	color: #f18f01;
	font-size: 10px;
	margin-left: -15px;
}
</style>
