<template>
	<b-modal id="notification-dispatch" ref="notification-dispatch" size="lg" 
		:title="title" @cancel="handleClose" ok-only>
		<div v-if="Object.keys(dispatch).length === 0" :style="{ height: '100px' }">
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		</div>
		<div v-else>
			<!-- Primary Information -->
			<b-row>
				<b-col md="12" class="md-left-text-sm-right">
					<b>PRIMARY INFORMATION</b>
				</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Source</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">
					{{ dispatch.source.company }} - {{ dispatch.source.storageLocation }}
				</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Destination</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">
					{{ dispatch.destination.company }} - {{ dispatch.destination.storageLocation }}
				</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Remarks</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">
					<span class="truncate-text">
						<truncate collapsed-text-class="collapsed" clamp="Show More" less="Show Less"
							type="html" :text="breakRemarks(dispatch.notes, 25)" :length="50" />
					</span>
				</b-col>
			</b-row>

			<!-- Transportation -->
			<b-row class="mt-4">
				<b-col md="12" class="md-left-text-sm-right">
					<b>TRANSPORTATION</b>
				</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Plate No./Conduction No.</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					dispatch.transportation.plateNo
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Company</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					dispatch.transportation.company
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Driver</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					dispatch.driver.name
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Assistants</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					!dispatch.transportation.assistants
						? '-'
						: dispatch.transportation.assistants
				}}</b-col>
			</b-row>
		</div>
	</b-modal>
</template>

<script>
// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from "vue-truncate-collapsed";

export default {
	name: 'notification-dispatch',
	components: {
		Loading,
		truncate
	},
	data() {
		return {
			dispatch: {},
			isLoading: true,
		};
	},
	computed: {
		title() {
			return 'Dispatch Details ' + this.dispatch.dispatchId;
		},
	},
	mounted() {
		EventBus.$on('onShowDispatchDetails', dispatch => {
			this.dispatch = dispatch;
			this.$bvModal.show('notification-dispatch');
		});
	},
	methods: {
		handleClose() {
			this.$bvModal.hide('notification-dispatch');
		},
		breakRemarks(remarks, length) {
			return remarks.length > length
				? remarks.replace(new RegExp(`([^\\s]{${length}})`, "g"), "$1<br>")
				: remarks;
		},
	}
};
</script>